import React from "react";
import { useSelector } from "react-redux";
import {
  CircularLoading,
  CircularProgress,
  CircularMainText,
} from "../../styledComponents/loading";
import { RootState } from "../../modules/Index";

export const ProgressLoading = () => {
  const { loading, projectLoading, roadLoading } = useSelector(
    ({ project, projectDefault, projectErrors }: RootState) => ({
      loading: projectDefault.loading,
      projectLoading: project.projectLoading,
      roadLoading: projectErrors.roadloading,
    })
  );
  return (
    <div>
      {(loading || projectLoading || roadLoading) && (
        <CircularLoading>
          <CircularProgress />
          <CircularMainText>F.O.M PLATFORM</CircularMainText>
        </CircularLoading>
      )}
    </div>
  );
};

export default ProgressLoading;

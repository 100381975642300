import React from 'react';
import { FlexContainer } from '../../styledComponents/layout';
import { mainColor600 } from '../../model/themeColor';


interface SelectedPnuButtonTabProps {
  pnuList: Array<string>;
  onClickPnuBtn: (pnu: string) => void;
  activePnu?: string;
}

export const SelectedPnuButtonTab = ({ onClickPnuBtn, ...props}: SelectedPnuButtonTabProps) => {  

  return (
    <FlexContainer
      direction="row"
      width="90%"
      justifyContent="flex-start"     
      height="40"      
      style={{ margin: '10px 10px', overflow: 'auto' }}
    >
      {props.pnuList.map((item: string, index: number) => 
        <div 
          key={`${item}_${index}`}         
          onClick={()=> onClickPnuBtn(item)}
        >
          <button style={{
            fontSize: '12px',
            width: '24px',
            height: '24px',            
            background: `${(item === props.activePnu)? '#fff': mainColor600}`,
            border: `1px solid ${(item === props.activePnu)? mainColor600: '#cccccc'}`,
            color:  `${(item === props.activePnu)? mainColor600: '#fff'}`,
            borderRadius: '6px',
            margin: '2px',
            outline: 'none'
          }}>{index+1}</button>
        </div>          
       )}
    </FlexContainer>
  )
}


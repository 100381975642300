import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules/Index';
import { FlexContainer } from '../../styledComponents/layout';
import { SelectedPnuButtonTab } from './SelectedPnuButtonTab';
import InfoSection from './InfoSection';
import { mainColor400 } from '../../model/themeColor';

export const SelectedPnuInfoSection = () => {

  const { pnuList, parcelData, jiguData } = useSelector(
    ({ project, parcelInfo, jiguByArea }: RootState) => ({
      pnuList: project.pnuList,
      parcelData: parcelInfo.data,
      jiguData: jiguByArea.data
    }),
  );
 
  const [selectedParcelData, setSelectedParcelData] = useState<any>(undefined);
  const [selectedJiguData, setSelectedJiguData] = useState<any>(undefined);

  const onClickPnuBtn = (pnu: string) => {
    const filteredData = parcelData.find((item: any) => pnu === item.pnu);
    setSelectedParcelData(filteredData);    
  }
   

  useEffect(() => {
    if((pnuList.length === jiguData.length) && (pnuList.length === parcelData.length) &&  (jiguData.length) && (parcelData.length) &&  (pnuList.length)) {
      setSelectedParcelData(parcelData[Math.abs(parcelData.length - 1)]);
    }
    
  },[pnuList, jiguData, parcelData]);
 
  useEffect(() => {
    if(selectedParcelData && jiguData.length > 0) {     
      const findResult = jiguData.find((item: any) => item.pnu === selectedParcelData.pnu);
      if(!findResult) return;
      setSelectedJiguData(findResult);
    }
    
  }, [selectedParcelData, jiguData]);

  return (
    <div style={{ display: 'flex', width: "100%"}}>
      { (pnuList.length === jiguData.length) && (jiguData.length === parcelData.length) && (jiguData.length) && (parcelData.length) &&  (pnuList.length) && selectedParcelData && (         
        <FlexContainer
          width="90%"
          height="auto"  
          direction="column"        
          style={{ 
            border: `1px solid ${mainColor400}`,
            borderRadius: '10px',
            marginLeft: '20px',
            paddingBottom: '10px'
          }}
        >          
          <SelectedPnuButtonTab
            onClickPnuBtn={onClickPnuBtn}
            pnuList={pnuList}
            activePnu={selectedParcelData? selectedParcelData.pnu : undefined}
          />
          <InfoSection 
            parceldata={selectedParcelData}
            jigudata={selectedJiguData}            
          />
        </FlexContainer>
      )}
     
    </div>
  )
}

export default SelectedPnuInfoSection;
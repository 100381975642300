import { Button } from "@material-ui/core";
import { FlexContainer } from "../../styledComponents/layout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../modules/Index";
import { ShapeType } from "../../model/Project";
import { changeDrawShape, changeShapeType } from "../../modules/Project";
import { mainColor400 } from "../../model/themeColor";

interface Props {
  type: ShapeType;
  labelTitle: string;
  runTitle?: string;
}

export default function ShapeTypeButton({ type, labelTitle, runTitle }: Props) {
  const dispatch = useDispatch();
  const { shapeType, drawShape } = useSelector(({ project }: RootState) => ({
    shapeType: project.shapeType,
    drawShape: project.drawShape,
  }));

  const selected = type === shapeType;
  const runable = selected && drawShape;

  return (
    <FlexContainer
      width="90%"
      height="auto"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Button
        variant="outlined"
        style={{
          width: "50%",
          textAlign: "start",
          background: selected ? mainColor400 : "#fff",
          color: selected ? "#fff" : "#000",
        }}
        onClick={() => dispatch(changeShapeType({ shapeType: type }))}
      >
        {labelTitle}
      </Button>
      <Button
        variant="outlined"
        style={{
          width: "30%",
          background: runable ? mainColor400 : "#fff",
          color: runable ? "#fff" : "#000",
          display: runTitle && selected ? undefined : "none",
        }}
        onClick={() => dispatch(changeDrawShape({ drawShape: !drawShape }))}
      >
        {runTitle}
      </Button>
    </FlexContainer>
  );
}

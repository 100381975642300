import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getFieldInfoAtPoint, initializeFieldInfo } from "../modules/FieldInfo";
import { showWarningMessage } from "../modules/InformMessage";
import { logout } from "../modules/Auth";
import {
  changeSelectMode,
  getRoadSite,
  updateProject,
  deletePolygon,
  setDeletePolygon,
  changeDrawShape,
  modifyProject,
  ProjectData,
} from "../modules/Project";
import MapManager, { NCoordinate } from "../MapManager";
import { RootState } from "../modules/Index";
import { Button } from "@material-ui/core";
import "../css/MapContent.scss";
import DrawingManager from "../DrawingManager";
import proj4 from "proj4";
import { Project, ProjectSiteType, ShapeType } from "../model/Project";
import * as turf from "@turf/turf";
import { Polygon } from "../DrawingManager";
import graphlib, { Graph } from "graphlib";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { setShowProjectList } from "../modules/ProjectList";
import ListIcon from "@material-ui/icons/List";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { searchObjects } from "../modules/SearchObject";
import debounce from "lodash/debounce";
import * as jsts from "jsts";
import useHospitalMarkers from "../hooks/useHospitalMarkers";
import useSchoolMarkers from "../hooks/useSchoolMarkers";
import {
  bProj,
  calibratePolygon,
  check_nearby_polygon,
  getProjectSiteCenter,
  nProj,
  sumOfSite,
  toGeom,
  toTurfPolygon,
  toWKT,
} from "../utils/Geometry";
import { mainColor400 } from "../model/themeColor";

export type reset_type = "All" | "Value";

export const MapContent = () => {
  // map
  const mapManager = useRef(new MapManager());
  const pnuList = useRef<string[]>([]);
  const [onCadastral, setOnCadastral] = useState(true);
  // type
  const currentSiteMode = useRef<ProjectSiteType>("SELECT");
  const polygonEvent = useRef(undefined);
  // polygon
  const originProjectSiteGraph = useRef<Graph>(new Graph());
  const selectedProjectSiteGraph = useRef(new Graph());
  const projectSite = useRef<Polygon[]>([]);
  const originProjectSite = useRef<Polygon[]>([]);
  const vacancyInside = useRef<Polygon[]>([]);
  const drawSitePolygon = useRef<Polygon | null>(null);
  const drawShapeType = useRef<ShapeType>("PROJECT_SITE");
  const drawAllowed = useRef<boolean>(true);
  const roadValue = useRef<number[][]>([]);
  const polygon_calibration = useRef<any>(undefined);

  useHospitalMarkers(mapManager.current);
  useSchoolMarkers(mapManager.current);
  const dm = useRef<DrawingManager>(
    new DrawingManager(mapManager.current.getMap())
  );
  // const roadSite = useRef<Polygon | null>(null);

  const {
    fieldData,
    isDeletePolygon,
    mapCenter,
    projectSiteMode,
    session,
    showList,
    draw,
    shapeType,
    projectLoading,
  } = useSelector(
    ({ fieldInfo, addressInfo, project, projectList, auth }: RootState) => ({
      fieldData: fieldInfo.data,
      isDeletePolygon: project.deletePolygon,
      mapCenter: addressInfo.mapCenter,
      projectSiteMode: project.data.project_site_type,
      session: auth.session,
      showList: projectList.showList,
      draw: project.drawShape,
      shapeType: project.shapeType,
      projectLoading: project.projectLoading,
    })
  );

  const initializePolygon = () => {
    // initialize roadValue;
    roadValue.current = [];
    // initialize pnuList
    pnuList.current = [];
    switch (currentSiteMode.current) {
      case "DRAW": {
        // remove &  initialize drawSitePolygon
        drawSitePolygon.current?.remove(true);
        drawSitePolygon.current = null;
        polygonEvent.current = undefined;
        break;
      }
      case "SELECT": {
        // remove & initialize selectedProjectSiteGraph
        selectedProjectSiteGraph.current
          .nodes()
          .map((p) => selectedProjectSiteGraph.current.node(p).remove(true));
        selectedProjectSiteGraph.current = new Graph();
        // remove & initialize projectSite
        projectSite.current.map((p) => p.remove(true));
        projectSite.current = [];

        originProjectSiteGraph.current
          .nodes()
          .map((p) => originProjectSiteGraph.current.node(p).remove(true));
        originProjectSiteGraph.current = new Graph();
        originProjectSite.current.map((p) => p.remove(true));
        originProjectSite.current = [];
        break;
      }
      default:
        break;
    }
    // remove & initialize vacancyInside
    vacancyInside.current.forEach((p) => p.remove());
    vacancyInside.current = [];
  };

  /**
   * shapeType trigger
   */
  useEffect(() => {
    drawShapeType.current = shapeType;

    const MAX_ZINDEX = 0;

    selectedProjectSiteGraph.current.nodes().forEach((p) =>
      selectedProjectSiteGraph.current.node(p).setOptions({
        clickable: shapeType === "PROJECT_SITE",
        zIndex:
          shapeType === "PROJECT_SITE" && projectSiteMode === "SELECT"
            ? MAX_ZINDEX
            : DrawingManager.DrawingOption.PROJECT_SITE.zIndex,
      })
    );
    projectSite.current.forEach((p) =>
      p.setOptions({
        clickable: shapeType === "PROJECT_SITE",
        zIndex:
          shapeType === "PROJECT_SITE"
            ? MAX_ZINDEX
            : DrawingManager.DrawingOption.PROJECT_SITE.zIndex,
      })
    );
    vacancyInside.current.forEach((p) =>
      p.setOptions({
        clickable: shapeType === "VACANCY_INSIDE",
        zIndex:
          shapeType === "VACANCY_INSIDE"
            ? MAX_ZINDEX
            : DrawingManager.DrawingOption.VACANCY_INSIDE.zIndex,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapeType]);

  /**
   * draw trigger
   */
  useEffect(() => {
    drawAllowed.current = draw;
    if (mapManager.current.getMap() == null) return;
    if (draw && drawShapeType.current === "VACANCY_INSIDE") drawShape();
    /**
     * draw trigger
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draw]);

  const dispatch = useDispatch();

  const onMove = (e: any) => {
    const zoom = mapManager.current.getMap().getZoom();
    dispatch(
      searchObjects({
        bounds: e,
        zoom,
      })
    );
  };

  useEffect(() => {
    const mapContainer = document.getElementById("map");
    mapManager.current.createMap(
      mapContainer!,
      {
        center: undefined, //  {lat: 35.1780033, lng: 128.1455271},
        zoom: 18,
      },
      onMove
    );
    window.onresize = () => {
      resizeMap();
    };
    dm.current.setMap(mapManager.current.getMap());
    mapManager.current.on("click", onClickMap);

    resizeMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentSiteMode.current !== projectSiteMode) {
      initializePolygon();
      currentSiteMode.current = projectSiteMode;

      if (projectSiteMode === "SELECT") {
        setCadastral(true);
        dm.current.removeDrawingListener();
        dm.current.clearDrawingManager();

        dispatch(
          searchObjects({
            bounds: mapManager.current.getMap().getBoundary(),
            zoom: mapManager.current.getMap().getZoom(),
          })
        );
      } else {
        setCadastral(false);
        drawShape();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSiteMode]);

  useEffect(() => {
    if (isDeletePolygon) {
      initializePolygon();
      dispatch(setDeletePolygon(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeletePolygon]);

  const resizeMap = () => {
    mapManager.current.relayout();
  };
  const setCadastral = (on: boolean) => {
    setOnCadastral(on);
    if (mapManager.current) mapManager.current.onCadastralLayer(on);
  };

  /*
   * title: 지도 클릭 event 동작
   * var: coord(위경도 좌표)
   */
  const onClickMap = (e: any) => {
    switch (drawShapeType.current) {
      case "PROJECT_SITE": {
        if (currentSiteMode.current === "SELECT") {
          const coord: NCoordinate = { lat: e.coord._lat, lng: e.coord._lng };
          const coordr = proj4(nProj, bProj, [coord.lng, coord.lat]);

          dispatch(
            getFieldInfoAtPoint({
              x: coordr[0],
              y: coordr[1],
            })
          );
        } else if (drawSitePolygon.current?.getCurrentState() === "ADDED") {
          dispatch(deletePolygon());
        }
        break;
      }
      case "VACANCY_INSIDE": {
        break;
      }
      default:
        break;
    }
  };

  const setGrpahNode = (polygon: Polygon) => {
    let differ = false;
    let isOverlap = false;
    let type = "";

    const nodes = selectedProjectSiteGraph.current.nodes();
    for (let i = 0; i < nodes.length; i++) {
      const reader = new jsts.io.WKTReader();
      const p1 = reader.read(toWKT(polygon));
      const p2 = reader.read(
        toWKT(selectedProjectSiteGraph.current.node(nodes[i]))
      );

      isOverlap = p1.intersects(p2);
      type = p1.union(p2).getGeometryType();

      if ((type === "MultiPolygon" && isOverlap) || !isOverlap) {
        differ = true;
      }

      if (isOverlap && type === "Polygon") {
        selectedProjectSiteGraph.current.setNode(polygon.getId()!, polygon);
        selectedProjectSiteGraph.current.setEdge(nodes[i], polygon.getId()!);
        differ = false;
        return;
      }
    }

    // 1개 제한
    if (differ) {
      selectedProjectSiteGraph.current
        .nodes()
        .map((p) => selectedProjectSiteGraph.current.node(p).remove(true));
      selectedProjectSiteGraph.current = new Graph();
      projectSite.current.map((p) => p.remove());
      projectSite.current = [] as Polygon[];
      pnuList.current = [pnuList.current[pnuList.current.length - 1]];

      isOverlap = false;
      polygon_calibration.current = fieldData.polygon_calibration;
    } else {
      polygon_calibration.current =
        polygon_calibration.current ?? fieldData.polygon_calibration;
    }

    if (isOverlap === false) {
      selectedProjectSiteGraph.current.setNode(polygon.getId()!, polygon);
    }
  };

  const setOriginGraphNode = (polygon: Polygon) => {
    let differ = false;
    let isOverlap = false;
    let type = "";

    const nodes = originProjectSiteGraph.current.nodes();
    for (let i = 0; i < nodes.length; i++) {
      const reader = new jsts.io.WKTReader();
      const p1 = reader.read(toWKT(polygon));
      const p2 = reader.read(
        toWKT(originProjectSiteGraph.current.node(nodes[i]))
      );

      isOverlap = p1.intersects(p2);
      type = p1.union(p2).getGeometryType();

      if ((type === "MultiPolygon" && isOverlap) || !isOverlap) {
        differ = true;
      }

      if (isOverlap && type === "Polygon") {
        originProjectSiteGraph.current.setNode(polygon.getId()!, polygon);
        originProjectSiteGraph.current.setEdge(nodes[i], polygon.getId()!);
        differ = false;
        return;
      }
    }

    // 1개 제한
    if (differ) {
      originProjectSiteGraph.current
        .nodes()
        .map((p) => originProjectSiteGraph.current.node(p).remove(true));
      originProjectSiteGraph.current = new Graph();
      originProjectSite.current.map((p) => p.remove());
      originProjectSite.current = [] as Polygon[];

      isOverlap = false;
    }

    if (isOverlap === false) {
      originProjectSiteGraph.current.setNode(polygon.getId()!, polygon);
    }
  };

  const unionProjectSite = (callback?: Function) => {
    // projectSite.map(p => clearRoads(p));
    projectSite.current = [];
    // let project_site = [];
    let union: Array<any> = [];
    graphlib.alg
      .components(selectedProjectSiteGraph.current)
      .forEach((g, i) => {
        g.forEach((p, j) => {
          if (union[i]) {
            union[i] = turf.union(
              union[i],
              toTurfPolygon(selectedProjectSiteGraph.current.node(p))
            );
          } else {
            union[i] = toTurfPolygon(selectedProjectSiteGraph.current.node(p));
          }
        });
      });

    const bigSiteAreas = union.map((r) => turf.area(r));
    const bigSiteIndex = bigSiteAreas.indexOf(Math.max(...bigSiteAreas));

    let bigUnion;
    if (bigSiteIndex > -1) {
      bigUnion = union[bigSiteIndex];
      union[bigSiteIndex].geometry.coordinates[0].splice(
        union[bigSiteIndex].geometry.coordinates[0].length - 1,
        1
      );
      const polygon = dm.current.addPolygon(
        bigUnion.geometry.coordinates,
        { ...DrawingManager.DrawingOption.PROJECT_SITE, simplify: true },
        true
      );

      polygon.setOptions({ visible: false });
      projectSite.current.push(polygon as Polygon);

      graphlib.alg
        .components(selectedProjectSiteGraph.current)
        .forEach((r, i) => {
          if (i !== bigSiteIndex) {
            r.forEach((rr) => {
              let p = selectedProjectSiteGraph.current.node(rr);
              p.remove();
              selectedProjectSiteGraph.current.removeNode(rr);
              let index = pnuList.current!.findIndex((e) => e === p.getPnu());
              if (index > -1) {
                pnuList.current!.splice(index, 1);
              }
            });
          }
        });
    }
    if (callback) {
      callback();
    }
  };

  const unionOriginProjectSite = (callback?: Function) => {
    originProjectSite.current = [];
    let union: any[] = [];
    graphlib.alg.components(originProjectSiteGraph.current).forEach((g, i) => {
      g.forEach((p, j) => {
        if (union[i]) {
          union[i] = turf.union(
            union[i],
            toTurfPolygon(originProjectSiteGraph.current.node(p))
          );
        } else {
          union[i] = toTurfPolygon(originProjectSiteGraph.current.node(p));
        }
      });
    });

    const bigSiteAreas = union.map((r) => turf.area(r));
    const bigSiteIndex = bigSiteAreas.indexOf(Math.max(...bigSiteAreas));

    let bigUnion;
    if (bigSiteIndex > -1) {
      bigUnion = union[bigSiteIndex];
      union[bigSiteIndex].geometry.coordinates[0].splice(
        union[bigSiteIndex].geometry.coordinates[0].length - 1,
        1
      );
      const polygon = dm.current.addPolygon(
        bigUnion.geometry.coordinates,
        { ...DrawingManager.DrawingOption.PROJECT_SITE, simplify: true },
        true
      );

      polygon.setOptions({ visible: false });
      originProjectSite.current.push(polygon as Polygon);

      graphlib.alg
        .components(originProjectSiteGraph.current)
        .forEach((r, i) => {
          if (i !== bigSiteIndex) {
            r.forEach((rr) => {
              let p = originProjectSiteGraph.current.node(rr);
              p.remove();
              originProjectSiteGraph.current.removeNode(rr);
              let index = pnuList.current!.findIndex((e) => e === p.getPnu());
              if (index > -1) {
                pnuList.current!.splice(index, 1);
              }
            });
          }
        });
    }
    if (callback) {
      callback();
    }
  };

  const onUpdateProject = (update: Project) => {
    dispatch(updateProject(update));
  };

  const makeRoads = async (polygon: Polygon) => {
    const polygonIndex = projectSite.current.findIndex(
      (e) => e.getId() === polygon.getId()
    );

    const outerPath = polygon.getPath()[0];

    for (let i = 0; i < outerPath.length; i++) {
      let width = 1;
      if (
        roadValue.current[polygonIndex] !== undefined &&
        roadValue.current[polygonIndex][i] !== undefined
      ) {
        width = roadValue.current[polygonIndex][i];
      } else {
        if (roadValue.current[polygonIndex] === undefined) {
          roadValue.current[polygonIndex] = [];
        }
        roadValue.current[polygonIndex].push(width);
      }
    }
  };

  const onUpdateShape = (type?: ShapeType) => {
    switch (type) {
      case "PROJECT_SITE": {
        // polygon 다시 설정할때: 삭제?
        const update: ProjectData = {};
        update.project_site = projectSite.current.map((p) => toWKT(p));
        update.origin_project_site = originProjectSite.current.map((p) =>
          toWKT(p)
        );
        if (projectSite.current.length === 0) {
          polygon_calibration.current = update.polygon_calibration = undefined;
        } else {
          update.polygon_calibration = polygon_calibration.current;
        }

        update.project_site_center = getProjectSiteCenter(
          projectSite.current
        ).centerWKT;
        update.field_info_pnu = pnuList.current;
        if (projectSiteMode === "DRAW") {
          update.project_site_area = Number(
            sumOfSite(projectSite.current.map((p) => toWKT(p))).toFixed(1)
          );
          makeRoads(projectSite.current[0] as Polygon);
          update.road_value = roadValue.current;
          dispatch(getRoadSite(update));
        } else {
          onUpdateProject(update);
        }
        break;
      }
      case "VACANCY_INSIDE": {
        const update: Project = {};
        const wktList = vacancyInside.current.map((p) => toWKT(p));
        update.vacancy_inside = wktList;
        update.vacancy_inside_area = parseInt(sumOfSite(wktList).toFixed(1));
        dispatch(modifyProject(update));
        break;
      }
      default:
        break;
    }
  };

  // 프로젝트 run => projectLoading === true, 폴리곤 초기화
  useEffect(() => {
    if (projectLoading) {
      initializePolygon();
    }
  }, [projectLoading]);

  useEffect(() => {
    if (
      fieldData!.pnu &&
      !["도", "공", "천", undefined].includes(fieldData!.jimog)
    ) {
      const pnu = fieldData!.pnu;
      if (pnuList.current!.findIndex((e) => e === pnu) < 0) {
        pnuList.current.push(pnu);

        const nodes = selectedProjectSiteGraph.current.nodes();
        if (nodes.length !== 0) {
          for (let i = 0; i < nodes.length; i++) {
            const each_wkt = toWKT(
              selectedProjectSiteGraph.current.node(nodes[i])
            );
            if (!check_nearby_polygon(each_wkt, fieldData!.geom!)) {
              polygon_calibration.current = undefined;
              break;
            }
          }
        }
        const calibrated_polygon = calibratePolygon(
          fieldData!.geom!,
          polygon_calibration.current ?? fieldData!.polygon_calibration
        );
        //   console.log(fieldData!.geom!, typeof(fieldData!.geom!));// fieldData.geom
        const originPolygon = dm.current.addPolygon(
          toGeom(fieldData.geom, "Field").coordinates,
          {
            ...DrawingManager.DrawingOption.PROJECT_SITE,
            simplify: false,
            visible: false,
          },
          true
        );

        const polygon = dm.current.addPolygon(
          toGeom(calibrated_polygon, "Field").coordinates,
          { ...DrawingManager.DrawingOption.PROJECT_SITE, simplify: false },
          true
        );
        polygon.setRightClick(false);
        polygon.setPnu(fieldData!.pnu);
        polygon.on("click", () => {
          const index = pnuList.current!.findIndex(
            (e) => e === (polygon as Polygon).getPnu()
          );
          pnuList.current!.splice(index, 1);
          selectedProjectSiteGraph.current.removeNode(polygon.getId()!);
          polygon.remove();
          dispatch(initializeFieldInfo());
          unionProjectSite();
          unionOriginProjectSite();
          onUpdateShape("PROJECT_SITE");
        });
        setGrpahNode(polygon);
        setOriginGraphNode(originPolygon);
        unionProjectSite();
        unionOriginProjectSite();
        onUpdateShape("PROJECT_SITE");
      }
    } else if (fieldData!.pnu && ["도", "공", "천", undefined]) {
      dispatch(
        showWarningMessage({
          msg: "도로, 공지, 하천 등은 선택하실 수 없습니다.",
          width: 370,
          autoClose: 3000,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldData!.pnu]);

  useEffect(() => {
    if (mapCenter) {
      const coord = { lat: mapCenter[1], lng: mapCenter[0] };
      mapManager.current.setCenter(coord);
    }
  }, [mapCenter, mapManager]);

  const drawShape = () => {
    switch (drawShapeType.current) {
      case "PROJECT_SITE": {
        if (projectSiteMode !== "DRAW") return;
        if (drawSitePolygon.current != null) {
          drawSitePolygon.current.remove(true);
        }
        drawSitePolygon.current = dm.current.drawPolygon(
          DrawingManager.DrawingOption.PROJECT_SITE
        ) as Polygon;
        drawSitePolygon.current.on("added", () => {
          if (drawSitePolygon.current.getPath()[0].length < 4) {
            drawSitePolygon.current.emit("cancelled");
            return;
          }

          dm.current.deleteLastVertex(drawSitePolygon.current);

          polygonEvent.current = "ADDED";
          dm.current.ccwVertex(drawSitePolygon.current);
          projectSite.current.push(drawSitePolygon.current);
          originProjectSite.current.push(drawSitePolygon.current);
          // clearRoad();
          onUpdateShape("PROJECT_SITE");
          drawSitePolygon.current.setCurrentState("ADDED");
        });

        drawSitePolygon.current.on("click", () => {
          drawSitePolygon.current.setEditable(true);
        });

        drawSitePolygon.current.on(
          "changed",
          debounce(async () => {
            if (
              polygonEvent.current !== "ADDED" &&
              projectSite.current.length > 0
            ) {
              onUpdateShape("PROJECT_SITE");
              drawSitePolygon.current.setEditable(false);
            } else {
              polygonEvent.current = undefined;
            }
          }, 500)
        );

        drawSitePolygon.current.on("removed", () => {
          polygonEvent.current = "REMOVED";
          if (
            drawSitePolygon.current !== null &&
            drawSitePolygon.current.getCurrentState()
          )
            drawSitePolygon.current.setCurrentState("REMOVED");
          clearProjectSite();
        });

        drawSitePolygon.current.on("cancelled", () => {
          drawSitePolygon.current!.remove(true);
          clearProjectSite();
        });
        break;
      }
      case "VACANCY_INSIDE": {
        const poly = dm.current.drawPolygon(
          DrawingManager.DrawingOption.VACANCY_INSIDE
        );
        poly.on("added", () => {
          if (poly.getPath()[0].length < 4) {
            poly.emit("cancelled");
            return;
          }
          dm.current.deleteLastVertex(poly);
          vacancyInside.current.push(poly);

          onUpdateShape("VACANCY_INSIDE");

          dispatch(changeDrawShape({ drawShape: false }));
        });

        poly.on("cancelled", () => {
          poly.remove(true);
        });

        poly.on("removed", () => {
          vacancyInside.current = vacancyInside.current.filter(
            (p) => p.getId() !== poly.getId()
          );
          onUpdateShape("VACANCY_INSIDE");
        });

        break;
      }
      default:
        break;
    }
  };

  const clearProjectSite = () => {
    projectSite.current = [];
    originProjectSite.current = [];

    if (projectSiteMode === "DRAW") drawShape();
  };

  return (
    <div className="MapContent">
      <div id="map" style={{ width: "100px", height: "100px" }}></div>
      <div className="btn-groups-left">
        <button
          onClick={() => setCadastral(!onCadastral)}
          style={{
            fontFamily: "Roboto",
            fontSize: "12px",
            width: "80px",
            height: "30px",
            cursor: "pointer",
            background: `${onCadastral ? "#fff" : mainColor400}`,
            border: `1px solid ${onCadastral ? mainColor400 : "#cccccc"}`,
            color: `${onCadastral ? mainColor400 : "#fff"}`,
            borderRadius: "6px",
            margin: "2px",
            outline: "none",
          }}
        >
          지적편집도
        </button>
        <button
          onClick={() =>
            dispatch(
              changeSelectMode({
                mode: projectSiteMode === "SELECT" ? "DRAW" : "SELECT",
              })
            )
          }
          style={{
            fontFamily: "Roboto",
            fontSize: "12px",
            width: "100px",
            height: "30px",
            cursor: "pointer",
            background: `${projectSiteMode === "SELECT" ? "#fff" : mainColor400}`,
            border: `1px solid ${
              projectSiteMode === "SELECT" ? "#000000" : "#cccccc"
            }`,
            color: `${projectSiteMode === "SELECT" ? mainColor400 : "#fff"}`,
            borderRadius: "6px",
            margin: "2px",
            outline: "none",
          }}
        >
          {projectSiteMode === "SELECT" ? "필지 선택 모드" : "그리기 모드"}
        </button>
        <Button
          onClick={() =>
            dispatch(
              setShowProjectList({
                showList: !showList,
              })
            )
          }
          startIcon={<ListIcon />}
          style={{
            fontFamily: "Roboto",
            fontSize: "12px",
            width: "150px",
            height: "30px",
            background: `${showList ? "#fff" : mainColor400}`,
            border: `1px solid ${showList ? mainColor400 : "#cccccc"}`,
            color: `${showList ? "#000" : "#fff"}`,
            borderRadius: "6px",
            margin: "2px",
            outline: "none",
          }}
        >
          음압병동 검토목록
        </Button>
      </div>
      <div className="btn-groups-right">
        <Button
          startIcon={<PermIdentityIcon />}
          style={{
            fontFamily: "Roboto",
            fontSize: "12px",
            height: "30px",
            background: "#ffffff",
            border: `1px solid ${mainColor400}`,
            color: mainColor400,
            borderRadius: "6px",
            margin: "2px",
            outline: "none",
          }}
        >
          {session && session.id}
        </Button>
        <Button
          onClick={() => dispatch(logout())}
          startIcon={<ExitToAppIcon />}
          style={{
            fontFamily: "Roboto",
            fontSize: "12px",
            width: "100px",
            height: "30px",
            background: mainColor400,
            border: "1px solid #cccccc",
            color: "#fff",
            borderRadius: "6px",
            margin: "2px",
            outline: "none",
          }}
        >
          로그아웃
        </Button>
      </div>
    </div>
  );
};

export default MapContent;

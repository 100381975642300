import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../modules/Index";
import { modifyProject } from "../../modules/Project";
import {
  FlexContainer,
  HorizontalEmptyDivide,
} from "../../styledComponents/layout";
import ShapeTypeButton from "./ShapeTypeButton";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export const ExtraInfoSection = () => {
  const { projectData } = useSelector(({ project }: RootState) => ({
    projectData: project.data,
  }));

  const dispatch = useDispatch();

  // const onChangeBuildingStories = (event: any) => {
  //   const targetValue = Number(event.target.value);
  //   dispatch(
  //     modifyProject({
  //       building_stories_max: targetValue,
  //       building_stories_min: targetValue,
  //       building_stories_avg: targetValue,
  //     })
  //   );
  // };

  const onChangeFloorType = (_, newFloorType: 0 | 1) => {
    dispatch(
      modifyProject({
        floor_type: newFloorType,
      })
    );
  }


  return (
    <FlexContainer
      direction="column"
      height="auto"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <HorizontalEmptyDivide />
      <ShapeTypeButton type="PROJECT_SITE" labelTitle="대지영역" />
      <HorizontalEmptyDivide />
      <ShapeTypeButton
        type="VACANCY_INSIDE"
        labelTitle="배치제한영역 추가"
        runTitle="설정하기"
      />
      <HorizontalEmptyDivide />
      <FlexContainer
        width="90%"
        height="auto"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {/* <RowsKeyNumInputValue
          keyName="최고층수 입력"
          // keyName="평당 공사비(만원)"

          value={projectData.building_stories_max}
          inputName="최고 층수 입력"
          width="48%"
          onChange={onChangeBuildingStories}
          disableUnderline
          help="설계 검토에 반영할 건물 최고 층수를 입력할 수 있습니다"
          boxWidth="100%"
          inputProps={{ min: 1, max: 5 }}
        /> */}
        <ToggleButtonGroup
          style={{ width: 396, height: 36 }}
          value={projectData.floor_type}
          exclusive
          onChange={onChangeFloorType}
          aria-label="text alignment"
        >
          <ToggleButton color="primary" style={{ width: "100%" }} value={0} aria-label="저층형" >
            저층형
          </ToggleButton>
          <ToggleButton style={{ width: "100%" }} value={1} aria-label="고층형">
            고층형
          </ToggleButton>
        </ToggleButtonGroup>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ExtraInfoSection;

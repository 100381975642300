

// import styled from 'styled-components';
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button';
import { mainColor600 } from '../model/themeColor';
// import { styled as mu_styled } from '@material-ui/core/styles';

interface BtnProps { 
  width?: string;
  height?: string;
  
  radius?: string;
  onClick?: (e:any) => void;  
  disabled?: boolean;
  runnable?: boolean;
  active?: boolean;
}

export const LogoutBtn = styled(Button)<BtnProps>`
  width: ${props => props.width || '50%' };
  height: 25px;
  font-size: 14px;
  color: #444;
  font-weight: 700;
  background-color: #aaa;
  border: 1px solid #555;
  border-radius: 15px;    
`;

export const RunButton = styled.button<BtnProps>`  
  width: 90% ;
  height: 40px;
  font-style: normal;
  font-weight: 700;  
  background: ${props => props.runnable? mainColor600 :'#cccccc'};  
  border: 1px solid ${props => props.runnable? mainColor600 :'#cccccc'};
  border-radius: 6px;  
  color: ${props => props.runnable? '#ffffff':'#e9e9e9'};  
  font-size: 18px;
  text-align: center;  
  outline: none;
  cursor: pointer;

  &:hover {    
    background-color: ${props => props.runnable? '#ffffff':'#cccccc'};  
    color: ${props => props.runnable? mainColor600 :'#e9e9e9'};  
    transform: scale(1.05);
    border-color: ${props => props.runnable? mainColor600 :'#e9e9e9'};  
    outline: none;
  }
`;

export const CheckButton = styled.button<BtnProps>`  
  width: auto;
  height: 32x;
  font-style: normal;
  font-weight: 700;  
  background: #ffffff;  
  border: 1px solid ${props => props.active? mainColor600 :'#cccccc'};
  border-radius: 6px;  
  color: ${props => props.active? '#000000':'#cccccc'};  
  font-size: 12px;
  margin: 2px 2px;
  text-align: center;  
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover { 
    color: ${props => props.active? '#cccccc': mainColor600};     
    border-color: ${props => props.active? '#cccccc': mainColor600};  
    outline: none;
  }
`;



export const PnuBtn = styled.button`
  width: 24px;
  height: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;  
  background-color: mainColor600;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #fff;
  font-size: 10px;
  text-align: center;
  
  :hover {    
    background-color: #ddd;
    transform: scale(1.05);
    border-color: mainColor600;  
    outline: none;
  }
  :focus {        
    outline: none;
  } 
`;


export const TextButton = styled.div<BtnProps>`  
  width: 50%;
  height: 20px;
  font-family: Roboto;
  font-style: normal;  
  font-size: 12px;
  letter-spacing: -0.05em;
  color: rgba(0, 0, 0, 0.87);
  text-align: right;
  font-weight: 600;  
  border-bottom: 1px solid #000;
`;


// import styled from 'styled-components';

// import { jsx, css } from '@emotion/core'

import styled from '@emotion/styled'
import { mainColor400, mainColor600 } from '../model/themeColor';
interface InfoTextProps {
  align?: string;
  padding?: string;  
  width?: string;
  height?: string;
  background?: string;  
  type?: string;
 
}

interface InfoTitleProps {
  width?: string;
}


interface SecionTitleProps {
  align?: string;
  padding?: string;  
  width?: string;
  height?: string;
  background?: string;  
  type?: string;
  fontStyle?: string;
  fontSize?: string;
  fontWeight?: string | number;
  color?: string;
}


export const InfoTextDiv = styled.div<InfoTextProps>`
  width: 85%;
  height: 70px;
  text-align: ${props => props.align || 'left'};
  padding: ${props => props.padding || '0px 20px'};
  background: ${props => props.background || 'white'};
  font-family: Roboto;
  font-style: normal;
  font-weight: ${props => (props.type === 'primary') ? 800 : 300};
  font-size: ${props => props.type === 'primary' ? '16px' : '13px'};
  // line-height: 24px;
  letter-spacing: -0.05em;
  color: #000;  
`

export const KeyValueTitle = styled.div<InfoTitleProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  width: ${props => props.width || '100%'};
  height: 17px;
  font-size: 12px;
  letter-spacing: -0.05em;
  color: #808080;
  text-align: left;
  vertical-align: middle;
  margin-right: 5px;
`;

export const KeyValueContents = styled.div<InfoTitleProps>`
  font-family: Roboto;  
  font-style: normal;
  font-weight: 500;
  width: ${props => props.width || '98%'};
  font-size: 16px;
  height: auto;
  line-height: 24px;
  letter-spacing: -0.05em;
  color: #333333;
  text-align: left;
  vertical-align: middle;
  white-space:normal;
`;

export const InfoTitleText = styled.div<InfoTitleProps>`
font-family: Roboto;
width: ${props => props.width || '100%'};
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
letter-spacing: -0.05em;
color: ${() => mainColor400};
text-align: left;
white-space:normal;
`;


export const SectionTitleText =  styled.div<SecionTitleProps>`
  font-family: Roboto;
  width: ${props => props.width || '100%'};
  text-align: ${props => props.align || 'left'};
  padding: ${props => props.padding || '0px 20px'};
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 'bold'};
  font-size: ${props => props.fontSize || '18px'};
  letter-spacing: -0.05em;
  color:${props => props.color || 'rgba(0, 0, 0, 0.87'};
  white-space:normal;
`;

export const SectionSubTitleText =  styled.div<SecionTitleProps>`
  font-family: Roboto;
  width: ${props => props.width || '100%'};
  text-align: ${props => props.align || 'left'};
  padding: ${props => props.padding || '0px 20px'};
  font-style: ${props => props.fontStyle || 'normal'};
  font-weight: ${props => props.fontWeight || 700};
  font-size: ${props => props.fontSize || '16px'};
  letter-spacing: -0.05em;
  color:${props => props.color || '#808080'};
  white-space:normal;
`;


export const HilightValueContents =  styled.span<InfoTitleProps>`
  font-family: Roboto;  
  font-style: normal;  
  width: ${props => props.width || '98%'};
  font-weight: 700;
  font-size: 16px;
  letter-spacing: -0.05em;
  color: ${() => mainColor600};
  white-space:normal;
  text-align: left;
`;

interface circleProps {
  color?: string;
}

export const LegendCircle = styled.div<circleProps>`
  background: ${props => props.color || '#80CBC4'};
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;


export const LegendPlain = styled.div`
  width: 90%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.05em;
  white-space:normal;
  text-align: left;
  color: #808080;
`;

import React, { useRef, useState } from 'react';
import SiteAnalysis from './SiteAnalysis';
import '../../css/ResultInfo.scss';
import ArchitectureOverview from './ArchitectureOverview';
import ReactToPrint from 'react-to-print';
import FloorDivision from './FloorDivision';
import { Button, CircularProgress } from '@material-ui/core';

import ResultOverview from './ResultOverview';

import { useSelector } from 'react-redux';
import { RootState } from '../../modules/Index';

export interface ResultPageProps {
  open?: boolean,
  setUnitState?: Function,
  unitState?: string, // ㎡ | 평
  address?: string,

}


const ResultInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { resultInfoData } = useSelector(
    ({ selectedProject }: RootState) => ({
      resultInfoData: selectedProject.result,      
    }),
  );
  const componentRef = useRef(null);
  const [unitState, setUnitState] = useState('㎡');
  // const [open, setOpen] = useState(true); 
  const convertUnit = () => { // 단위 변환
    if (unitState === "㎡") {
      setUnitState('평');
    } else {
      setUnitState('㎡');
    }
  }
  const { address } = useSelector(
    ({ selectedProject }: RootState) => ({
      address: selectedProject.project.주소목록,
    }),
  );

  const downloadDesigner = () => {
    const url = "https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Designer/FOM/fom_design_solution_installer_release.exe";
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.click();
    window.URL.revokeObjectURL(url);
    setIsLoading(false);
  }   

  return (
    <>
      <ReactToPrint
        // onBeforeGetContent={toggleAddressList}
        // onAfterPrint={() => setOpen(true)}
        trigger={() => <Button className="btn btn-primary btn-outline print-btn">인쇄</Button>}
        content={() => componentRef.current
        }
        documentTitle={address && address.length === 1 ? `${address[0]}` : `${address[0]}외 ${address.length - 1}필지`}
      />
      <div className="ResultInfo" >  
        <div className="unit-btns"> 
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "㎡" ? 'active' : ''}`} onClick={convertUnit}>㎡</button>
          <button className={`btn btn-primary btn-outline btn-unit ${unitState === "평" ? 'active' : ''}`} onClick={convertUnit}>평</button>
        </div>
        <Button 
          className="download-btn"
          onClick={async (e) => {
            setIsLoading(true);
            const webSocket = new WebSocket("ws://localhost:30169");
            webSocket.onopen = async (e) => {
              const url = `builditfom://run?email=${resultInfoData.email}&code=${resultInfoData.designer_code}`;
              window.location.href = url;
              setIsLoading(false);
            };
            webSocket.onerror = (e) => {
              console.log(e, 'error')
              downloadDesigner();
            };
          }}
          >
          {isLoading ? <div style={{width: 121.56, display: "flex", alignItems: "center", justifyContent: "center"}}><CircularProgress style={{ width: 16, height: 16, color: "#eee" }} /></div> : <div>F.O.M 디자인 솔루션</div>}
        </Button>
        <div className="print-wrap" ref={componentRef}>
          <ResultOverview unitState={unitState}></ResultOverview>
          <SiteAnalysis unitState={unitState} setUnitState={setUnitState}></SiteAnalysis>
          <ArchitectureOverview unitState={unitState} setUnitState={setUnitState}></ArchitectureOverview>
          <FloorDivision></FloorDivision>
          {/* <FloorHouseholdSummaryWrapper unitState={unitState} setUnitState={setUnitState}></FloorHouseholdSummaryWrapper> */}
          {/* <BusinessFeasibility unitState={unitState} ></BusinessFeasibility>           */}
        </div>

      </div>
      
    </>
  );
};
export default ResultInfo;

import React from 'react';
import { useSelector, useDispatch  } from 'react-redux';

import { RootState } from '../modules/Index';
// import '../css/ProjectForm.scss';
import AddressSearchSection from './menusection/AddressSearchSection';
import { KeyValueTitle, HilightValueContents } from '../styledComponents/text';


import ExtraInfoSection  from './menusection/ExtraInfoSection';
import SelectedPnuInfoSection from './menusection/SelectedPnuInfoSection';
// import PurchaseConditionSection from './menusection/PurchaseConditionSection';
// import BasicDataSection from './menusection/BasicDataSection';
import { MainLeftPaneDiv, RunPaneDiv, FlexContainer, KeyValueContainer, LogoPaneDiv, MainPaneDiv,  LogoDiv, HorizontalEmptyDivide } from '../styledComponents/layout'

import { Button } from '@material-ui/core';
import { SectionTitleText } from '../styledComponents/text';
// import ProjectResult from './ProjectResult';

import RunComponent from './menusection/RunComponent';
import { InfoTextDiv } from '../styledComponents/text'
import { setShowProjectList } from '../modules/ProjectList';
import { ProjectListModal } from './ProjectListModal';
import DrawingInfoSection from './menusection/DrawingInfoSection';
import { mainColor100, mainColor400, mainColor600 } from '../model/themeColor';

export default function ProjectForm() {
  const { pnulist, fieldSelectMode, projectData } = useSelector(
    ({ project }: RootState) => ({      
      projectData: project.data,    
      pnulist: project.pnuList,
      fieldSelectMode: project.data.project_site_type
    }),
  );
  
  const dispatch = useDispatch();
  const onShowProjectList = () => {
    dispatch(setShowProjectList({
      showList: true
    }));
  }

  return (    
    <MainLeftPaneDiv>     
      <LogoPaneDiv>
        <FlexContainer       
          direction="column"
          height="auto"
          background={mainColor100}
        >
          <LogoDiv>
          F.O.M 음압병동 솔루션 <br/>(Free plan One-day Modular) PLATFORM
          </LogoDiv>        
          <AddressSearchSection />       
        </FlexContainer>      
      </LogoPaneDiv>
      <MainPaneDiv>
        {(projectData.project_site.length === 0) && (
          <FlexContainer       
            direction="column" 
            justifyContent="flex-start"
            height="auto"        
            padding="12px 0px"
          >
            <Button 
              style={{
                width: '90%', border: `1px solid ${mainColor600}`, color: mainColor600,
                fontFamily: 'Roboto', fontStyle: 'normal', fontWeight: 'bold',
                fontSize: '18px', height: "32px"
              }}
              className="btn btn-primary btn-outline project-list-btn"
              onClick={() => onShowProjectList()}
            > 
            
            음압병동 검토 목록 열기
            </Button>
            <HorizontalEmptyDivide />    
            <FlexContainer
              width="90%"
              height="auto" 
              direction="column"   
              padding="10px 0px"        
              style={{ border: `1px solid ${mainColor400}`, borderRadius: '6px'}}
            >
              <InfoTextDiv type="primary">
                주소 검색을 통해 행정구역의 병원 및 학교를 검색하고, 
                검색된 필지 또는 지도상의 임의 필지를 선택하여
                결과를 검토할 수 있습니다.
              </InfoTextDiv>              
            </FlexContainer>           
          </FlexContainer>
        )}   
        {
          (fieldSelectMode === 'DRAW') && (projectData.project_site.length > 0) && (<DrawingInfoSection />)
        }     
        {
          (pnulist.length > 0)  && (fieldSelectMode === 'SELECT') && (
          <FlexContainer       
            direction="column" 
            justifyContent="flex-start"
            alignItems="flex-start"
            height="auto"        
            padding="12px 0px"
          >
            <HorizontalEmptyDivide />      
            <FlexContainer       
              direction="row" 
              justifyContent="space-between"
              alignItems="flex-start"
              height="35px"        
              padding="12px 0px"
              width="96%"
            >
              <SectionTitleText width="30%" align="left" padding="0px 0px 10px 20px">선택한 필지 {pnulist.length}</SectionTitleText>
              {/* <FlexContainer       
                direction="row" 
                justifyContent="flex-end"
                alignItems="flex-start"
                height="35px"                      
                width="50%"
              >
                <KeyValueTitle width="100px">총 대지 면적(m²)</KeyValueTitle>
                <KeyValueContents width="100px">{projectData!.project_site_area!.toFixed(1).toString()}m²</KeyValueContents>    
              </FlexContainer> */}
               <KeyValueContainer     
                  width="50%"
                  height="auto"
                  justifyContent="flex-end"
                >
                  <FlexContainer
                    justifyContent="flex-end"
                    width="98%"
                    padding="0px 20px 0px 0px"
                  >
                    <KeyValueTitle width="100px">총 대지 면적(m²)</KeyValueTitle>                 
                  </FlexContainer>
                  <FlexContainer
                    justifyContent="flex-end"
                    width="98%"
                    padding="0px 20px 0px 0px"
                  >
                    <HilightValueContents width="100px">{projectData!.project_site_area!.toFixed(1).toString()}m²</HilightValueContents>
                  </FlexContainer>
                </KeyValueContainer>                    
            </FlexContainer>          
            <SelectedPnuInfoSection />                      
            
           
          </FlexContainer>
        )}
        {(projectData.project_site.length > 0) && (<ExtraInfoSection />)}
         {/* <HorizontalEmptyDivide /> 
            <HorizontalEmptyDivide /> 
            <FeasibilityConfigSection />
            <PurchaseConditionSection />
            <HorizontalEmptyDivide />      
            <HorizontalEmptyDivide />      
            <BasicDataSection /> */}  
      </MainPaneDiv>
      {/* <PriceInfoConfig /> */}
      <ProjectListModal />
      <RunPaneDiv>
        <RunComponent />
      </RunPaneDiv>
      
    </MainLeftPaneDiv>
    
  )
}